var $ = require("jquery");
//var breakpoint = require('./app-ddslick.js');
var breakpoint = require('./app-breakpoints');
var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width();
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, ""); 
var _margin = '';

function initMap(mapinfo) {
	if ($.isArray(mapinfo.datalat) && $.isArray(mapinfo.datalng)) {
		var myLat = mapinfo.datalat;
		var myLng = mapinfo.datalng;
		var myLatLng = { lat: myLat[0].value, lng: myLng[0].value };
	} else {
		var myLatLng = { lat: mapinfo.datalat, lng: mapinfo.datalng };
	}
	
	if (mapinfo.center) {
		var center_map = mapinfo.center;
	}else{
		var center_map = myLatLng;
	}

	var styles = [ 
	{
		"featureType": "administrative", 
			"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#2a2569"
					}
				]
	},
	{
		"featureType": "landscape",
			"elementType": "all",
				"stylers": [
					{
						"color": "#f2f2f2"
					}
				]
	},
	{
		"featureType": "poi",
			"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
	},
	{
		"featureType": "road",
			"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 45
					}
				]
	},
	{
		"featureType": "road.highway",
			"elementType": "all",
				"stylers": [
					{
						"visibility": "simplified"
					}
				]
	},
	{
		"featureType": "road.highway",
			"elementType": "geometry",
				"stylers": [
					{
						"visibility": "simplified"
					},
					{
						"color": "#dedede"
					}
				]
	},
	{
		"featureType": "road.arterial",
			"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
	},
	{
		"featureType": "transit",
			"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
	},
	{
		"featureType": "water",
			"elementType": "all",
				"stylers": [
					{
						"color": "#ffffff"
					},
					{
						"visibility": "simplified"
					}
				]
	}
];
	
	//console.log(center_map);
	
	var map = new google.maps.Map(document.getElementById(mapinfo.id), {
		zoom: mapinfo.zoom,
		disableDefaultUI: mapinfo.disableDefaultUI,
		draggable: mapinfo.draggable,
		draggableCursor: mapinfo.draggableCursor,
		mapTypeId: mapinfo.maptype,
		center: center_map,
		mapTypeControl: false,
		scaleControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		zoomControl: false,
		scrollwheel: false,
		styles: styles
		
	});
	if (mapinfo.disableDefaultUI == false) {
		
		var image = document.location.origin + '/app/themes/wp-ecoledeconduite/dist/pin_default.png';

		var bounds = new google.maps.LatLngBounds();
		var infowindow = new google.maps.InfoWindow();  

		var marker, i;

		for (i = 0; i < myLat.length; i++) {
			image = document.location.origin + '/app/themes/wp-ecoledeconduite/dist/pin_' + (i+1) + '.png';
			marker = new google.maps.Marker({
				position: new google.maps.LatLng(myLat[i].value, myLng[i].value),
				map: map,
				icon: image
		
			});

			//extend the bounds to include each marker's position
			bounds.extend(marker.position);

			// google.maps.event.addListener(marker, 'click', (function (marker, i) {
			// 	return function () {
			// 		infowindow.setContent(locations[i][0]);
			// 		infowindow.open(map, marker);
			// 	}
			// })(marker, i));
			
			
			/*google.maps.event.addListener(marker, 'click', function () {
				console.log(marker);
				console.log('https://www.google.com/maps/dir/Current+Location/' + marker.lat + ',%20' + marker.lng);
				window.open('https://www.google.com/maps/dir/Current+Location/' + marker.lat + ',%20' + marker.lng);
			});*/
			google.maps.event.addListener(marker, 'click', (function (marker, i) {
				return function () {
					
					window.open('https://www.google.com/maps/dir/Current+Location/' + myLat[i].value + ',%20' + myLng[i].value);
				}
			})(marker, i));
		}
		//now fit the map to the newly inclusive bounds
		map.fitBounds(bounds);
	}
}

window.preparMap = function () {
	//bounds = new google.maps.LatLngBounds();
	//infowindow = new google.maps.InfoWindow();
	var datalat = "";
	var datalng = "";
	var id = "";
	var disableDefaultUI = false;
	var draggable = true;
	var draggableCursor = '';
	var zoom = 10;
	var marker = "default";
	var maptype = "roadmap";
	var center;
	$('.vy_google_map').each(function () {
		datalat = $(this).data('lat');
		datalng = $(this).data('lng');
		// console.log(datalat[0].value);
		// console.log(datalng);

		if ($(this).attr('data-zoom')) {
			zoom = parseInt($(this).data('zoom'));
		}
		if ($(this).attr('data-center-lat') && $(this).attr('data-center-lng')) {
			center = { lat: $(this).data('center-lat'), lng: $(this).data('center-lng') };
		}
		if ($(this).attr('data-marker')) {
			marker = $(this).data('marker');
		}
		if ($(this).attr('data-maptype')) {
			maptype = $(this).data('maptype');
		}
		id = $(this).attr('id');
		if ($(this).data('disableui') == true) {
			disableDefaultUI = $(this).data('disableui');
			draggable = false;
			draggableCursor = 'default';
		}

		// console.log('---vy_google_map---');
		// console.log('ID: ' + id);
		// console.log('LAT: ' + datalat);
		// console.log('LNG: ' + datalng);
		// console.log('center: ');
		// console.dir(center);
		// console.log('zoom: ' + zoom);
		// console.log('disableDefaultUI: ' + disableDefaultUI);
		// console.log('draggable: ' + draggable);
		// console.log('draggableCursor: ' + draggableCursor);
		// console.log('marker: ' + marker);
		var mapinfo = { datalat: datalat, datalng: datalng, id: id, zoom: zoom, disableDefaultUI: disableDefaultUI, draggable: draggable, draggableCursor: draggableCursor, marker: marker, center: center, maptype: maptype };
		initMap(mapinfo);
	});
}

function loadScript(callback) {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
		'&callback=' + callback + '&key=AIzaSyDJYrHsr4OpB6oYZn5fqcglEU-h2kCX1FI';
	document.body.appendChild(script);
}
if (document.getElementsByClassName("vy_google_map").length > 0) {
	window.onload = loadScript('preparMap');
}

$(function() {
	// $(window).scroll(function() {
	// 	if(_gridLabel != "smartphone") {
		
	// 	}
	// });

  $(window).resize(function(){
		_gridSize = breakpoint().match(/(\d+)/g);
		_winSize = $(window).width();
		_margin = (_winSize - _gridSize) / 2;

		_gridLabel = breakpoint().replace(/\d+/g, '').replace(' ','');

		if(_gridLabel == "smartphone") {
		
		}

	}).resize();
	
	// $('.vy_parent_submenu').hover(
	// 	function(){
	// 		$('.vy_submenu').toggle();
	// 		console.log('in');
	// 	}, function () {
	// 		$('.vy_submenu').toggle();
	// 		console.log('out');
	// 	}
	// );

	$('.vy_submenu').appendTo('.vy_parent_submenu')

	if($("#frm_field_67_container select").length){
		var select_bg = $("#frm_field_67_container select").css('background-image');
		select_bg = select_bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");
	}
	function addBgSelect() {
		/* Custom select design */
		var option_bg = $("#frm_field_67_container select option:selected").css('background-image');
		option_bg = option_bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");
		var option_value = $("#frm_field_67_container select option:selected").val();
		// console.log(option_bg);
		// console.log(select_bg);
		// console.log(option_value);
		if (option_bg != 'none') {
			//console.log('bg');
			var bgimage = 'url("' + select_bg + '"), url("' + option_bg + '")';
			
			$("#frm_field_67_container select").css('background-image', bgimage);
			$("#frm_field_67_container select").addClass('vy_select_image');
			if (option_value == 'Camion-remorque') {
				$("#frm_field_67_container select").css('padding-left', '100px');
			} else if (option_value == 'Cyclomoteur') {
				$("#frm_field_67_container select").css('padding-left', '65px');
			} else if (option_value == 'Motocyclette') {
				$("#frm_field_67_container select").css('padding-left', '75px');
			} else {
				$("#frm_field_67_container select").css('padding-left', '');
			}
		} else {
			//console.log('no bg');
			$("#frm_field_67_container select").css('background-image', '');
			$("#frm_field_67_container select").removeClass('vy_select_image');
			$("#frm_field_67_container select").css('padding-left', '');
		}
		/* End */
	};
	function changeSchool(){
		var selected_cours = $("#frm_field_67_container select").val();
		$("#frm_field_68_container select").parent('.frm_top_container').addClass('loading');
		$("#frm_field_68_container select").empty()

		var data = {
			action: 'filter_school', // function to execute
			afp_nonce: afp_vars.afp_nonce, // wp_nonce
			filters: selected_cours, // selected tag
			//post_id: $('.c-block-_units').attr('data-url'),
			icl_current_language: $('#icl_current_language').val(),
			//search: search, // selected tag
		};

		$.post(afp_vars.afp_ajax_url, data, function (response) {
			if (response) {
				// Display posts on page
				var selected_school = $("#frm_field_68_container select").val();

				$("#frm_field_68_container select").empty().append(response);
				$("#frm_field_68_container select").parent('.frm_top_container').removeClass('loading');
				$("#frm_field_68_container select").val(selected_school).trigger('change');

				/*if(!$("#frm_field_68_container select").val()){
					$(".frm_embed_form_container").hide();
				}*/
			};
		}); 
	};
	function changePayment(){
		var selected_cours = $("#frm_field_67_container select").val();
		$("#frm_field_149_container select").parent('.frm_top_container').addClass('loading');
		$("#frm_field_149_container select").empty();

		var data = {
			action: 'filter_payment', // function to execute
			afp_nonce: afp_vars.afp_nonce, // wp_nonce
			filters: selected_cours, // selected tag
			//post_id: $('.c-block-_units').attr('data-url'),
			icl_current_language: $('#icl_current_language').val(),
			//search: search, // selected tag
		};

		$.post(afp_vars.afp_ajax_url, data, function (response) {
			if (response) {
				// Display posts on page
				var selected_payment = $("#frm_field_149_container select").val();

				$("#frm_field_149_container select").empty().append(response);
				$("#frm_field_149_container select").parent('.frm_top_container').removeClass('loading');
				var exists = false;
				$('#frm_field_149_container select option').each(function(){
						if (this.value == selected_payment) {
								exists = true;
								$("#frm_field_149_container select").val(selected_payment).trigger('change');
								return false;
						}
				});

			};
		}); 
	};
	if($("#frm_field_67_container select").length){

		
		$.each( ddData, function( key, value ) {
			$("#frm_field_67_container select option[value='"+key+"']").attr("style","background-image:url("+value+")");
		});
		
		addBgSelect();
		changeSchool();
		changePayment();
	}
	
	$("#frm_field_67_container select").change(function () {
		addBgSelect();
		changeSchool();
		changePayment();
  });
});